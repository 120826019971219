<template>
    <Dialog style="width: 450px" v-model:visible="deleteDialog" modal :header="biaCode" :dismissable-mask="true">
        <div class="flex" style="width: 100%">
            <div class="flex align-items-center"
                style="border: 3px solid rgb(237 197 15); border-radius: 12px 0px 0px 12px; padding: 16px; background: antiquewhite;">
                <i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i>
            </div>
            <div class="flex"
                style="border: 3px solid rgb(237 197 15); border-radius: 0px 12px 12px 0px; padding: 16px; font-weight: 500; background: antiquewhite; border-left: none;">
                {{ this.$langGetValue('deleteProcessInstructions') }}
            </div>
        </div>
        <template #footer>
            <div style="width:100%" class="text-center">
                <Button autofocus
                    style="width: 100px; height: 45px; border-radius: 8px; background: var(--color_brand_medium)"
                    :label="this.$langGetValue('buttonCancel')" @click="deleteDialog = false" />
                <Button style="width: 100px; height: 45px; border-radius: 8px"
                    class="p-button p-component p-button-danger" :label="this.$langGetValue('confirmDeletion')"
                    @click="deleteProcess" />
            </div>
        </template>
    </Dialog>

    <div v-if="statusColor !== null && statusLabel !== null"
        style=" display: flex; align-items: center; flex-direction: row; width: 100%; padding: 6px 7px; background-color: white;">
        <div style="display: flex; flex-direction: row; align-items: center; gap: 2px; width: 55%; margin-right: 12px">
            <Skeleton shape="circle" size="16px" class="mr-2" v-tooltip.top="statusLabel" :style="{
                backgroundColor: statusColor
            }">
            </Skeleton>
            <div class="body2" style="color: var(--color_gray_dark); font-weight: 700;">
                {{ biaCode }}
            </div>
            <Divider layout="vertical" style="height: 100%;" />
            <div style="display: flex; flex-direction: column; justify-content: start; gap: 8px; width: 70%">
                <div class="body1" style="color: var(--color_gray_xdark); font-weight: 700;">
                    {{ description }}
                </div>
                <div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
                    <div class="body3" style="color: var(--color_gray_medium)">
                        <b>BPM:</b> {{ bpmName }}
                    </div>
                    <div class="body3" style="color: var(--color_gray_medium)">
                        <b>REX:</b> {{ rexName }}
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; align-items: center; width: 45%; justify-content: end;">
            <div v-if="stepper">
                <BiaFormStepper :statusInput="status" />
            </div>
            <Chip v-if="!stepper" class="temporaryChip" style="font-weight: 700;" :label="statusLabel"></Chip>
            <Divider layout="vertical" style="height: 100%;" />

            <div style="display: flex; align-items: center; gap: 12px; padding: 0px 16px 0px 0px"
                v-if="this.status == 'toFill'">
                <i v-if="user == bpmName || user == rexName" class="pi pi-pencil" v-tooltip.top="'Preencher'"
                    @click="loadProcess" style="font-size: 1.1rem; cursor: pointer"></i>
                <i v-else class="pi pi-search" v-tooltip.top="'Visualizar'" @click="loadProcess"
                    style="font-size: 1.1rem; cursor: pointer"></i>
            </div>

            <div style="display: flex; align-items: center; gap: 12px; padding: 0px 16px 0px 0px"
                v-if="this.status == 'reFill'">
                <i v-if="user == bpmName || user == rexName" class="pi pi-pencil" v-tooltip.top="'Revisar'"
                    @click="loadProcess" style="font-size: 1.1rem; cursor: pointer"></i>
                <i v-else class="pi pi-search" v-tooltip.top="'Visualizar'" @click="loadProcess"
                    style="font-size: 1.1rem; cursor: pointer"></i>
            </div>

            <div style="display: flex; align-items: center; gap: 12px; padding: 0px 16px 0px 0px"
                v-if="this.status == 'pendingBpmApproval'">
                <i v-if="user == bpmName" class="pi pi-check" v-tooltip.top="'Visualizar e validar'"
                    @click="loadProcess" style="font-size: 1.1rem; cursor: pointer"></i>
                <i v-else class="pi pi-search" v-tooltip.top="'Visualizar'" @click="loadProcess"
                    style="font-size: 1.1rem; cursor: pointer"></i>
            </div>

            <div style="display: flex; align-items: center; gap: 12px; padding: 0px 16px 0px 0px"
                v-if="this.status == 'pendingRoApproval'">
                <i v-if="checkRole(['*.storm.bia.*.manage'])" class="pi pi-check" v-tooltip.top="'Visualizar e validar'"
                    @click="loadProcess" style="font-size: 1.1rem; cursor: pointer"></i>
                <i v-else class="pi pi-search" v-tooltip.top="'Visualizar'" @click="loadProcess"
                    style="font-size: 1.1rem; cursor: pointer"></i>
            </div>

            <div style="display: flex; align-items: center; gap: 12px; padding: 0px 16px 0px 0px"
                v-if="this.status == 'closed'">
                <i class="pi pi-search" v-tooltip.top="'Visualizar'" @click="loadProcess"
                    style="font-size: 1.1rem; cursor: pointer"></i>
            </div>

            <i v-if="checkRole(['*.storm.bia.*.manage'])" class="pi pi-times" v-tooltip.top="'Deletar'"
                @click="deleteDialog = true;" style="font-size: 1.1rem; cursor: pointer; color: red"></i>
        </div>
    </div>
    <Divider layout="horizontal" style="height: 100%; padding: 0px;" />
</template>

<script>
import "../Global.css";
import BiaFormStepper from "../components/BiaFormStepper.vue"
import UserService from "../service/UserService.js"
import BiaService from "../service/BiaService"

export default {
    components: {
        BiaFormStepper,
    },
    props: {
        id: Number,
        biaCode: String,
        description: String,
        bpmName: String,
        rexName: String,
        status: String,
        stepper: Boolean,
    },
    data() {
        return {
            statusColor: null,
            statusLabel: null,
            user: UserService.user,
            deleteDialog: false
        };
    },
    mounted() {
        this.mapStatus()
    },
    methods: {
        checkRole(roles) {
            return UserService.checkUserRoleAny(roles)
        },
        loadProcess() {
            if (this.$route.query.id != undefined) {
                window.open(
                    window.location.origin + "/#/bia/campaign/form?campaingId=" + this.$route.query.id + "&formId=" + this.id,
                    "_blank"
                );
            } else {
                window.open(
                    window.location.origin + "/#/bia/campaign/form?formId=" + this.id,
                    "_blank"
                );
            }

        },
        deleteProcess() {
            BiaService.deleteProcess(this.id).then(() => {
                window.location.reload()
            })
        },
        mapStatus() { // colocar no contentService traduzido
            if (this.status == "toValidate") {
                this.statusColor = '#7A7A7A;'
                this.statusLabel = "Pendente validação"
            }
            if (this.status == "validated") {
                this.statusColor = '#D2E5FF'
                this.statusLabel = "Validado"
            }
            if (this.status == "toFill") {
                this.statusColor = '#6BAAFF'
                this.statusLabel = this.$langGetValue('biaStatus1')
            }
            if (this.status == "reFill") {
                this.statusColor = '#6BAAFF'
                this.statusLabel = this.$langGetValue('biaStatus5')
            }
            if (this.status == "pendingBpmApproval") {
                this.statusColor = '#195AB4'
                this.statusLabel = this.$langGetValue('biaStatus2')
            }
            if (this.status == "pendingRoApproval") {
                this.statusColor = '#0B2859'
                this.statusLabel = this.checkTenant() == 'btg' ? this.$langGetValue('biaStatus3') : this.$langGetValue('biaStatus3Pan')
            }
            if (this.status == "closed") {
                this.statusColor = '#479356'
                this.statusLabel = this.$langGetValue('biaStatus4')
            }
        },
        checkTenant() {
            return UserService.company
        }
    },
};
</script>
<style></style>